<template>
  <div>
    <div class="ctl-table-container">
      <pil-catalog-filters
        @refresh-filters="refreshItemsList"
        @refresh-sort-params="refreshItemsListWithSort"
        :catalogTab="tab"
      />
      <div class="ctl-titles">
        <label class="checkbox-container">
          <input
            type="checkbox"
            hidden
            ref="selectAll"
            @input="updateCheckedItemsList"
          />
          <div class="checkbox-item"></div>
        </label>
        <div class="grid">
          <span
            class="ctl-titles-item"
            v-for="(t, idx) of titlesList"
            :key="idx"
            >{{ t.name }}</span
          >
        </div>
      </div>
      <div class="ctl-items-list">
        <div
          class="ctl-item"
          :class="{ reversed: idx % 2 }"
          v-for="(l, idx) of items"
          :key="idx"
        >
          <label class="checkbox-container">
            <input
              type="checkbox"
              hidden
              :value="l"
              v-model="checkedItemsMod"
            />
            <div class="checkbox-item" :class="{ reversed: idx % 2 }"></div>
          </label>
          <div class="ctl-item-container grid" @click="showEditPopup(l)">
            <div class="avatar">
              <img
                alt="img"
                :src="l.avatar || require('@/assets/img/defaultUser.png')"
              />
            </div>
            <span class="ctl-item-param" style="max-width: 300px;">{{
              l.name || "-"
            }}</span>
            <div class="ctl-item-param" style="max-width: 300px;">
              {{ l.email }}
            </div>
            <div class="ctl-item-param">{{ l.role }}</div>
            <div class="ctl-item-param" style="white-space: pre">
              {{ showLimits({ limitsObj: l.limits }) }}
            </div>
            <div class="ctl-item-param">{{ l.active }}</div>
            <div class="ctl-item-param">{{ getCorrectDate(l.created_at) }}</div>
            <div class="ctl-item-param">
              {{ getCorrectDate(l.last_signin) }}
            </div>
            <div class="ctl-item-param">{{ l.confirm_email ? "+" : "-" }}</div>
          </div>
        </div>
        <div class="ctl-loader">
          <div
            v-infinity-scroll="{
              cb: fetchPathItemsList,
              tab
            }"
            id="scroll-trigger"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PilCatalogFilters from "@/components/catalog/Filters";
import catalogMixin from "@/mixins/catalogMixin";
import "@/assets/css/catalog.css";

export default {
  name: "managersCatalog",
  props: ["tab"],
  mixins: [catalogMixin],
  components: {
    PilCatalogFilters
  },
  data() {
    return {
      // ? fitlers
      filters: {},
      sortParams: { created_at: "desc" },
      // ? titles
      titlesList: [
        { name: "avatar preview" },
        { name: "fullname" },
        { name: "email" },
        { name: "user type" },
        { name: "limits" },
        { name: "active" },
        { name: "Sign up date" },
        { name: "Last sign in" },
        { name: "confirmed email" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      items: "catalog/users"
    })
  }
};
</script>

<style scoped>
.grid {
  display: grid;
  white-space: nowrap;
  grid-template-columns:
    minmax(100px, auto)
    minmax(300px, auto)
    minmax(300px, auto)
    minmax(150px, auto)
    minmax(130px, auto)
    minmax(100px, auto)
    minmax(130px, auto)
    minmax(130px, auto)
    minmax(100px, auto);
  grid-gap: 1vw;
  align-items: center;
  justify-items: center;
  min-width: fit-content;
  width: 100%;
}
.avatar img {
  max-width: 36px;
  border-radius: 50%;
  margin: 10px 0;
}
.ctl-item {
  height: 70px;
}
</style>
